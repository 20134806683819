import rocketSrc from "/public/icons/rocket.svg"
import boxSrc from "/public/icons/box.svg"
import chessSrc from "/public/icons/chess.svg"
import keywordsSrc from "/public/icons/keywords.svg"
import listingSrc from "/public/icons/listing.svg"
import seoSrc from "/public/icons/seo.svg"
import reportingSrc from "/public/icons/reporting.svg"
import advertisingSrc from "/public/icons/ad.svg"
import budgetSrc from "/public/icons/budget.svg"

export const services = [
  {
    title: "services.accountManagementAndSalesStrategy.title",
    items: [
      {
        iconSrc: rocketSrc,
        title:
          "services.accountManagementAndSalesStrategy.accountSetupAndOptimization.title",
        description:
          "services.accountManagementAndSalesStrategy.accountSetupAndOptimization.description",
        alt: "services.accountManagementAndSalesStrategy.accountSetupAndOptimization.alt",
      },
      {
        iconSrc: chessSrc,
        title:
          "services.accountManagementAndSalesStrategy.salesStrategyDevelopment.title",
        description:
          "services.accountManagementAndSalesStrategy.salesStrategyDevelopment.description",
        alt: "services.accountManagementAndSalesStrategy.salesStrategyDevelopment.alt",
      },
      {
        iconSrc: boxSrc,
        title:
          "services.accountManagementAndSalesStrategy.orderProcessingAndCustomerService.title",
        description:
          "services.accountManagementAndSalesStrategy.orderProcessingAndCustomerService.description",
        alt: "services.accountManagementAndSalesStrategy.orderProcessingAndCustomerService.alt",
      },
    ],
  },
  {
    hasBackground: true,
    title: "services.productListingAndSEOOptimization.title",
    items: [
      {
        iconSrc: listingSrc,
        title:
          "services.productListingAndSEOOptimization.productListingOptimization.title",
        description:
          "services.productListingAndSEOOptimization.productListingOptimization.description",
        alt: "services.productListingAndSEOOptimization.productListingOptimization.alt",
      },
      {
        iconSrc: keywordsSrc,
        title:
          "services.productListingAndSEOOptimization.keywordResearch.title",
        description:
          "services.productListingAndSEOOptimization.keywordResearch.description",
        alt: "services.productListingAndSEOOptimization.keywordResearch.alt",
      },
      {
        iconSrc: seoSrc,
        title: "services.productListingAndSEOOptimization.seoAudit.title",
        description:
          "services.productListingAndSEOOptimization.seoAudit.description",
        alt: "services.productListingAndSEOOptimization.seoAudit.alt",
      },
    ],
  },
  {
    title: "services.amazonAdvertisingManagement.title",
    items: [
      {
        iconSrc: advertisingSrc,
        title: "services.amazonAdvertisingManagement.amazonSponsoredAds.title",
        description:
          "services.amazonAdvertisingManagement.amazonSponsoredAds.description",
        alt: "services.amazonAdvertisingManagement.amazonSponsoredAds.alt",
      },
      {
        iconSrc: budgetSrc,
        title:
          "services.amazonAdvertisingManagement.advertisingBudgetManagement.title",
        description:
          "services.amazonAdvertisingManagement.advertisingBudgetManagement.description",
        alt: "services.amazonAdvertisingManagement.advertisingBudgetManagement.alt",
      },
      {
        iconSrc: reportingSrc,
        title:
          "services.amazonAdvertisingManagement.reportingAndAnalysis.title",
        description:
          "services.amazonAdvertisingManagement.reportingAndAnalysis.description",
        alt: "services.amazonAdvertisingManagement.reportingAndAnalysis.alt",
      },
    ],
  },
]
