import { useTranslation } from "next-i18next";
import amazonSmileSrc from "/public/img/amazon-smile.png";
import Container from "@/shared/components/Container";
import Button from "@/shared/components/Button";
import StrongTextParser from "@/shared/components/StrongTextParser";
import styles from "./OpeningSection.module.scss";
import Image from "next/image";

const OpeningSection = () => {
  const { t } = useTranslation("home");

  return (
    <section className={styles.opening} id="opening-section">
      <Container>
        <div className={styles.openingInner}>
          <div className={styles.mainTextWrapper}>
            <h1 className={styles.h1}>
              <StrongTextParser text={t("opening.h1")} />
            </h1>
            <h2 className={styles.h2}>{t("opening.h2")}</h2>
          </div>

          <div className={styles.highlightsWrapper}>
            <ul>
              <li className={styles.highlight}>
                <div>
                  <Image
                    src={amazonSmileSrc}
                    alt={t("opening.highlight1") as string}
                    className={styles.smile}
                    loading="lazy"
                    width={65}
                    height={20}
                  />
                </div>
                <span className={styles.highlightText}>
                  {t("opening.highlight1")}
                </span>
              </li>
              <li className={styles.highlight}>
                <div>
                  <Image
                    src={amazonSmileSrc}
                    alt={t("opening.highlight2") as string}
                    className={styles.smile}
                    loading="lazy"
                    width={65}
                    height={20}
                  />
                </div>
                <span className={styles.highlightText}>
                  {t("opening.highlight2")}
                </span>
              </li>
              <li className={styles.highlight}>
                <div>
                  <Image
                    src={amazonSmileSrc}
                    alt={t("opening.highlight3") as string}
                    className={styles.smile}
                    loading="lazy"
                    width={65}
                    height={20}
                  />
                </div>
                <span className={styles.highlightText}>
                  {t("opening.highlight3")}
                </span>
              </li>
            </ul>
          </div>

          <div>
            <Button
              text={t("opening.callToAction")}
              href="#contact"
              className={styles.callToActionButton}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OpeningSection;
