import { useTranslation } from "next-i18next"
import Container from "@/shared/components/Container"
import { ServiceProps } from "./types"
import Image from "next/image"
import styles from "./styles/Service.module.scss"

const Service = ({ title, items, hasBackground }: ServiceProps) => {
  const { t } = useTranslation("home")

  return (
    <div
      className={`${styles.serviceWrapper} ${
        hasBackground ? styles.hasBackground : ""
      }`}
    >
      <Container>
        <h3 className={styles.serviceTitle}>{t(title)}</h3>
        <ul>
          {items.map(({ iconSrc, title, description, alt }, index) => (
            <li key={title}>
              <div className={styles.item} key={title}>
                <div className={styles.iconWrapper}>
                  <Image
                    src={iconSrc}
                    className={styles.itemIcon}
                    loading="lazy"
                    alt={t(alt) as string}
                    width={90}
                    height={90}
                  />
                </div>
                <h4 className={styles.itemTitle}>{t(title)}</h4>
                <p className={styles.itemText}>{t(description)}</p>
              </div>
              {index < items.length - 1 && <hr className={styles.divider} />}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

export default Service
