import { useTranslation } from "next-i18next";
import logoSrc from "/public/icons/soem-labs-logo-white.svg";
import styles from "./AboutUsDescription.module.scss";
import Image from "next/image";
import Container from "@/shared/components/Container";
import StrongTextParser from "@/shared/components/StrongTextParser";
import Button from "@/shared/components/Button";

const AboutUsDescription = () => {
  const { t } = useTranslation("home");

  return (
    <section className={styles.wrapper} id="about-us">
      <Container>
        <Image
          src={logoSrc}
          alt="Soem labs logo"
          className={styles.logo}
          loading="lazy"
          width={300}
          height={55}
        />
        <div className={styles.textWrapper}>
          <p className={styles.paragraph}>
            <StrongTextParser text={t("aboutUsDescription.text1")} />
          </p>
          <p className={styles.paragraph}>
            <StrongTextParser text={t("aboutUsDescription.text2")} />
          </p>
          <p className={styles.paragraph}>
            <StrongTextParser text={t("aboutUsDescription.text3")} />
          </p>
          <p className={styles.paragraph}>
            <StrongTextParser text={t("aboutUsDescription.text4")} />
          </p>
        </div>
        <Button
          text={t("aboutUsDescription.talkToOurExperts")}
          href="#contact"
          className={styles.button}
        />
      </Container>
    </section>
  );
};

export default AboutUsDescription;
