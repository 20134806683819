import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Home from "@/containers/Home";
import { useEffect } from "react";

interface GetStaticProps {
  locale: string;
}

const HomePage = () => {
  useEffect(() => {
    const handleAnchorClick = (e: Event, anchor: Element) => {
      e.preventDefault();
      document
        .querySelector((anchor as any).getAttribute("href"))
        ?.scrollIntoView({
          behavior: "smooth",
        });
    };
    const addAnchorListener = (anchor: Element) =>
      anchor.addEventListener("click", function (e) {
        handleAnchorClick(e, anchor);
      });
    const removeAnchorListener = (anchor: Element) =>
      anchor.removeEventListener("click", function (e) {
        handleAnchorClick(e, anchor);
      });

    setTimeout(() => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        addAnchorListener(anchor);
      });
    }, 500);

    setTimeout(() => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        removeAnchorListener(anchor);
        addAnchorListener(anchor);
      });
    }, 1500);

    setTimeout(() => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        removeAnchorListener(anchor);
        addAnchorListener(anchor);
      });
    }, 3000);

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener("click", function (e) {
          handleAnchorClick(e, anchor);
        });
      });
    };
  }, []);

  return <Home />;
};

export const getStaticProps = async ({ locale }: GetStaticProps) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["home", "common"])),
    },
  };
};

export default HomePage;
