import Button from '@/shared/components/Button'
import Container from '@/shared/components/Container'
import { useTranslation } from 'next-i18next'
import styles from './AboutUsHighlights.module.scss'

const AboutUsHighlights = () => {
  const { t } = useTranslation('home')

  return (
    <Container>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {t('aboutUsHighlights.leaveItToThe')}
          <span className={styles.titleHighlight}>{t('aboutUsHighlights.pros')}</span>
        </h2>
        <div className={styles.highlightsWrapper}>
          <dl>
            <dd className={styles.highlightTitle}>{t('aboutUsHighlights.experience.title')}</dd>
            <dt className={styles.highlightDescription}>{t('aboutUsHighlights.experience.description')}</dt>

            <dd className={styles.highlightTitle}>{t('aboutUsHighlights.customerCentric.title')}</dd>
            <dt className={styles.highlightDescription}>{t('aboutUsHighlights.customerCentric.description')}</dt>

            <dd className={styles.highlightTitle}>{t('aboutUsHighlights.dataDriven.title')}</dd>
            <dt className={styles.highlightDescription}>{t('aboutUsHighlights.dataDriven.description')}</dt>
            
            <dd className={styles.highlightTitle}>{t('aboutUsHighlights.talentedTeam.title')}</dd>
            <dt className={styles.highlightDescription}>{t('aboutUsHighlights.talentedTeam.description')}</dt>
          </dl>

          <Button
            text={t('aboutUsHighlights.getAFreeAudit')}
            href="#contact"
            className={styles.button}
          />
        </div>
      </div>
    </Container>
  )
}

export default AboutUsHighlights