import { useTranslation } from "next-i18next"
import Head from "next/head"
import Layout from "@/shared/components/Layout"
import Loader from "@/shared/components/Loader"
import OpeningSection from "./OpeningSection"
import Services from "./Services"
import AboutUsDescription from "./AboutUsDescription"
import AboutUsHighlights from "./AboutUsHighlights"
import dynamic from "next/dynamic"
import { availableLanguages } from "@/shared/constants/languages"

const ContactProcess = dynamic(() => import("./ContactProcess"), {
  loading: () => <Loader />,
})
const ContactSection = dynamic(
  () => import("@/shared/components/ContactSection"),
  {
    loading: () => <Loader />,
  }
)
const Acknowledgements = dynamic(() => import("./Acknowledgements"), {
  loading: () => <Loader />,
})

const Home = () => {
  const { t, i18n } = useTranslation("home")

  return (
    <>
      <Head>
        <meta name="description" content={t("seo.description") as string} />
        <meta name="keywords" content={t("seo.keywords") as string} />
        <meta name="language" content={i18n.resolvedLanguage.toUpperCase()} />
        <meta name="classification" content="Business" />
        <meta property="og:title" content={t("seo.title") as string} />
        <meta property="og:url" content="https://soemlabs.com" />
        <meta property="og:image" content="/img/splash-screen.webp" />
        <title>{t("seo.title")}</title>
        {availableLanguages.map((language) => (
          <script
            key={language}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "http://schema.org",
                "@id": "https://soemlabs.com",
                inLanguage: language,
                "@type": "Corporation",
                name: "Soem Labs",
                description: t("seo.description"),
                email: "contact@soemlabs.com",
                url: "https://soemlabs.com",
                logo: {
                  url: "https://soemlabs.com/img/soem-labs-logo.png",
                  width: "230",
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                },
                sameAs: [
                  "https://www.linkedin.com/company/soemlabs",
                  "https://www.instagram.com/soemlabs/",
                ],
              }),
            }}
          />
        ))}
        {availableLanguages.map((language) => (
          <script
            key={language}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "http://schema.org",
                inLanguage: language,
                "@type": "Service",
                provider: {
                  "@type": "Corporation",
                  name: "Soem Labs",
                },
                serviceType: t("seo.serviceType"),
                hasOfferCatalog: {
                  "@type": "OfferCatalog",
                  name: t("seo.offerType"),
                  itemListElement: [
                    {
                      "@type": "OfferCatalog",
                      name: t(
                        "services.accountManagementAndSalesStrategy.title"
                      ),
                      itemListElement: [
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.accountManagementAndSalesStrategy.accountSetupAndOptimization.title"
                            ),
                          },
                        },
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.accountManagementAndSalesStrategy.salesStrategyDevelopment.title"
                            ),
                          },
                        },
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.accountManagementAndSalesStrategy.orderProcessingAndCustomerService.title"
                            ),
                          },
                        },
                      ],
                    },
                    {
                      "@type": "OfferCatalog",
                      name: t(
                        "services.productListingAndSEOOptimization.title"
                      ),
                      itemListElement: [
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.productListingAndSEOOptimization.productListingOptimization.title"
                            ),
                          },
                        },
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.productListingAndSEOOptimization.keywordResearch.title"
                            ),
                          },
                        },
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.productListingAndSEOOptimization.seoAudit.title"
                            ),
                          },
                        },
                      ],
                    },
                    {
                      "@type": "OfferCatalog",
                      name: t("services.amazonAdvertisingManagement.title"),
                      itemListElement: [
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.amazonAdvertisingManagement.amazonSponsoredAds.title"
                            ),
                          },
                        },
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.amazonAdvertisingManagement.advertisingBudgetManagement.title"
                            ),
                          },
                        },
                        {
                          "@type": "Offer",
                          itemOffered: {
                            "@type": "Service",
                            name: t(
                              "services.amazonAdvertisingManagement.reportingAndAnalysis.title"
                            ),
                          },
                        },
                      ],
                    },
                  ],
                },
              }),
            }}
          />
        ))}
      </Head>
      <Layout>
        <OpeningSection />
        <Services />
        <AboutUsDescription />
        <AboutUsHighlights />
        <ContactProcess />
        <ContactSection />
      </Layout>
      <Acknowledgements />
    </>
  )
}

export default Home
