import { useTranslation } from "next-i18next"
import Container from "@/shared/components/Container"
import { services } from "./constants"
import styles from "./styles/Services.module.scss"
import Service from "./Service"

const Services = () => {
  const { t } = useTranslation("home")

  return (
    <section id="services" className={styles.services}>
      <Container>
        <h2 className={styles.title}>{t("services.title")}</h2>
      </Container>
      {services.map(({ title, items, hasBackground }) => (
        <Service
          key={title}
          title={title}
          items={items}
          hasBackground={hasBackground}
        />
      ))}
    </section>
  )
}

export default Services
